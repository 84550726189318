<template>
<v-app>
    <div>
        <v-toolbar
                dense
                color="#2f9640"
                dark
                extended
                extension-height="120"
                flat
        >
            <v-toolbar-title  slot="extension" >                               

               <h4>Fale conosco</h4> 

                <v-layout row >
                    <v-flex wrap>
                        <v-btn outline small fab color="white" to="/home" class="outine-2">
                              <i class="fa fa-home fa-2x" ></i>
                        </v-btn>
                    </v-flex>
                   

                    
                </v-layout>
            </v-toolbar-title>
        </v-toolbar>

        <v-container>
        
        </v-container>

        <v-dialog v-model="finish" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Mensagem</v-card-title>
                <v-card-text>{{msg_finish}}</v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" flat @click="finalizar" >OK</v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>


    </div>




</v-app>
</template>

<script>
import { http } from '../../services/config'
import authHeader from '../../services/auth-header';
import { mapGetters, mapActions } from 'vuex'
import ConsultaBandeira from '../../services/bandeira';


    export default {
       
        computed:{
            ...mapGetters([
                    'lista_contratos' , 'cliente',  'contratoselecionado'
                ]),


        },

        data() {
            return {

                   finish: false,
                   msg_finish: '',
                   showload_e: false,
                   btn_load: false,
                   cep: '',
                   bairro: '',
                   cidade: '',
                   uf: '',
                   model: { model: {
                            PrefixoTelefone: "",
                            Telefone: "",
                            PrefixoCelular: "",
                            Celular: "",
                            Email: "",
                            Id: 0,
                            }
                        },


                    rules: [
                        value => !!value || 'Required.',
                    ],


            }
        },
      

        methods:{
       

               
                enviarAtualizacao() {
                    if (this.$refs.form.validate()) {
                    this.btn_load = true, 
                    this.model.model.Id = this.cliente.Id                      
                    http.post('ClienteDIO/Atualizar/', this.model, {
                            headers: authHeader()
                        }).then(response =>{
                         
                            if(response.data.Status === 0){
                                    this.msg_finish = "Parabéns, Seu contato foi atualizado sucesso!"
                                }else{
                                    this.msg_finish = "Ops, Informação não pode ser atualizado no momento, verifique as informações ou tente novamente mais tarde."
                                }
                                setTimeout(() => (this.btn_load = false, this.finish = true), 1500)
                            })
                            .catch(function (error) {
                                    this.msg_finish = "Ops, Servidor com problemas, por favor tente novamente mais tarde.",
                                    this.finish = true;
                                    this.btn_load = false;                  

                            });
                    }
                },

                finalizar(){
                    
                       this.finish = false
                       this.$router.push('/home')

                }
        
         }
       
    }

</script>

<style scoped>
    .outine-2 {
        border: 2px solid white;
    }

    .card--flex-toolbar {
        margin-top: -124px;
    }

    .learn-more-btn {
        text-transform: initial;
        text-decoration: underline;
    }
</style>